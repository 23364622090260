import React from 'react'
import { graphql } from 'gatsby'
import Article, { ARTICLE_VARIANT_TRIO, VARIANT_OPENER, VARIANT_TEXT_INTENDED, VARIANT_TEXT_CENTERED } from '../modules/Article.js'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components2/Layout'
import { VARIANT_TRIO } from '../components2/Puzzle'
import SEO from '../components/SEO'

const Page = ({ data }) => {
  const postNode = {
    title: `Polstern - ${config.siteTitle}`,
  }

  const block1 = {
    leading: 5,
    laces: true,
    variant: VARIANT_OPENER,
    headline: `Polstern`,
    lead: `Die traditionelle Handwerks&shy;kunst der Polster&shy;arbeiten liegt dem gelernten Raumausstatter-Meister und Restaurator im Handwerk Bernhard Ziegler und seinem erfahrenen Team besonders am Herzen.`,
    image: {
      alt: 'Placeholder',
      fluid: data.opener.childImageSharp.fluid
    },
  }
  
  const block2 = {
    dropcap: true,
    variant: VARIANT_TEXT_INTENDED,
    copytext: `<p>Ganz individuell und nach den Wünschen der Kunden werden in der hauseigenen Polsterwerkstatt alte Möbelstücke modern oder nach den historischen Techniken aufgepolstert. Wir setzen verschiedene Stilepochen wie Barock, Rockocko, Klassizismus oder Historismus um. Die alten Möbel werden je nach Befund mit Naturmaterialien wie Rosshaar oder Brechflachs, mit Leinen oder Palmfaser aufgepolstert. Auch Bezugsarbeiten mit Leder bieten wir als erfahrene Raumausstatter an.</p>`,
  }
  
  const blockQuote = {
    variant: VARIANT_TEXT_CENTERED,
    quote: `Bei uns ist das Aufpolstern und Neubeziehen von Möbel&shy;stücken all&shy;gegen&shy;wärtig und im täglichen Geschäft fest verankert.`,
  }
  
  const block3 = {
    variant: VARIANT_TEXT_INTENDED,
    copytext: `<p>Aber auch beim täglichen Aufarbeiten und Neubeziehen von Polstermöbeln jeglicher Art z.B. Eckbänke, Stühle, Bürostühle, Sessel und Sofas mit den unterschiedlichsten Materialien wie Alcantara, Leder, Wolle oder schwer entflammbare Materialien greifen unsere ausgebildeten Raumausstatter auf ihre langjährigen Erfahrungen zurück.</p>`
  }

  const blockPuzzle = {
    variant: ARTICLE_VARIANT_TRIO,
    // headline: `Neubeziehen eines Polstermöbels`,
    puzzle: {
      variant: VARIANT_TRIO,
      items: [
        {
          fluid: data.step1.childImageSharp.fluid
        },
        {
          fluid: data.step2.childImageSharp.fluid
        },
        {
          fluid: data.step3.childImageSharp.fluid
        }
      ]
    }
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Polstern - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="polstern" customTitle />

      <div>
        <article>
          <Article {...block1}></Article>
          <Article {...block2}></Article>
          <Article {...blockQuote}></Article>
          <Article {...block3}></Article>
          <Article {...blockPuzzle}></Article>
        </article>
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    opener: file(relativePath: { eq: "polstern-opener.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    step1: file(relativePath: { eq: "polstern-arbeitsschritte-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    step2: file(relativePath: { eq: "polstern-arbeitsschritte-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    step3: file(relativePath: { eq: "polstern-arbeitsschritte-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
